import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Form from '../Form';

const Section1 = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get('https://interior-admin.skyraan.net/api/category_list')
            .then((response) => {
                setCategories(response.data.data);
            })
            .catch((error) => {
                console.error('There was an error fetching the categories!', error);
            });
    }, []);

    const handleCardClick = (categoryUrl, id) => {
        console.log(categoryUrl, id);
        navigate(`/${encodeURIComponent(categoryUrl)}/${encodeURIComponent(id)}`);
    };

    const staticImages = [
        '/hero_images_1.png',
        '/hero_images_2.png',
        '/hero_images_3.png',
        '/hero_images_4.png',
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % staticImages.length);
        }, 4000); // Change image every 4 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [staticImages.length]);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Add animations duration for AOS
        });
    }, []);

  return (
    <div>
        <div className="section1">
            <div className="carousel slide carousel-fade hidden-xs" id="featured">
                <div className="carousel-inner">
                    {staticImages.map((image, index) => (
                        <div
                            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                            key={index}
                            style={{
                                opacity: index === activeIndex ? 1 : 0, // Fade in/out based on activeIndex
                                transition: 'opacity 1s ease-in-out', // Smooth transition for opacity
                                backgroundImage: `url(${image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '100vh', // Ensure it fills the screen
                            }}
                        >
                            <div className="carousel-overlay"></div>
                            <div className="carousel-caption text-center">
                                <h1 className="fw-bold text-light pb-2">
                                    Innovative and High-quality
                                </h1>
                                <h5 className="text-light pb-3">Interior fit-out & Furniture Solutions</h5>
                                <div className="row justify-content-center">
                                    <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12">
                                        <div className="row justify-content-center">
                                            {categories.map((item, catIndex) => (
                                                <div
                                                    key={catIndex}
                                                    onClick={() => handleCardClick(item?.category_url, item?.id)}
                                                    className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 pb-4"
                                                >
                                                    <div className="card py-xl-3 py-lg-3 py-md-3 py-sm-3 py-1 border-0 rounded-4 text-center cursor px-2 h-100">
                                                        <div className="text-center">
                                                            <img
                                                                className="hme-icon"
                                                                src={item.icon}
                                                                alt={item.title}
                                                            />
                                                        </div>
                                                        <h6 className="text-white top-title">{item.title.toUpperCase()}</h6>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="border-0 text-center sys-view">
                                            <button
                                                className="btn btn-bg text-dark buttons py-3 pxx-5 rounded-4"
                                                data-bs-dismiss="modal"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <h6 className="fw-normal mb-0">Get an Estimate</h6>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="section2 site_bg text-white ">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-1 col-md-0 col-sm-0 col-0"></div>                        
                   
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 ps-4 py-xl-5 py-lg-5 py-md-5 py-sm-4 py-3" data-aos="fade-up">
                        <h2 >Comprehensive Guide to </h2>
                        <h2>Interior Decoration by Ghats Interiors</h2>
                        <div className="pt-xl-4 pt-lg-4  pt-sm-4  pt-md-4  pt-3  lh-base" ><span >With over 12 years of experience, Ghats Interiors has established itself as a trusted name in interior fit-out joinery and furniture manufacturing in Dubai. We specialize in turning client visions into reality by delivering high-quality interiors that harmonize aesthetics with functionality. From living rooms and kitchens to gyms and kids’ rooms, we use premium materials to create spaces that truly reflect each client’s expectations and style. Ghats Interiors is your partner in crafting beautiful, lasting interiors tailored to every part of your home.</span></div>
                        <div className="text-center pt-4 pt-xl-4 pt-lg-4 pt-sm-3 pt-md-3">
                            <button className="btn border  px-5"><Link className='text-white text-decoration-none' to="/about-us "><span>Know More..</span></Link></button>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-0 col-4 text-end pe-0 sm-size">
                        <div className="pt-5 mt-5">
                        <img src="./section2.svg" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section3">
            <div className="container">
                <div className="row py-xl-5 py-lg-5 py-md-5 py-sm-5 py-3">
                    <div className="col-xl-6 col-lg-6 col-sm-12 col-md-5 col-12 text-center" >
                        <div><img className="w-75" src="./section3.svg" alt="" /></div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12 col-md-7 col-12 align-self-center pt-4 pt-xl-0 pt-lg-0 pt-sm-0 pt-md-0" data-aos="fade-up">
                        <h1 className="site_txt text-xl-start text-lg-start text-md-start text-sm-start text-center">We are Excited !!!</h1>
                        <p className='lb-lg' >Ghats Interiors in Coimbatore, Tamil Nadu, combines tradition with modern elegance to create custom interiors. With quality materials like wallpapers, mat flooring, and lighting, we craft spaces that reflect your unique taste and heritage.</p>
                        <div className="d-flex justify-content-center align-items-center gap-3 pe-5 ">
                            {/* <img className="sys-view" src="./location.svg" alt="" /> */}
                            {/* <div className="pt-2 sys-view">
                                <h4 className="fw-bold">Visit us at</h4>
                                <h6 className="">Plot 22/23/24, Keeranatham IT Park Road, Near TNEB Substation, Saravanampatti, Coimbatore, TamilNadu 641035</h6>
                            </div> */}
                            <div>
                                <p className='text-dark'>Experience our premium interior designs firsthand by visiting our flagship location in Saravanampatti, Coimbatore, Tamil Nadu. Discover the artistry and quality that define Ghats Interiors. For more information, reach out to us anytime.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='section4 py-3'>
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-12">
                        <h2 className='site_txt text-center'>Check Our Awesome Projects</h2>
                        <h5 className=' text-xl-center text-lg-center text-md-center text-sm-center text-start py-3'  data-aos="fade-up">If you're looking to go beyond ordinary wall art and want decor that truly reflects your personality, explore our collection of interior design projects. See how we bring unique, personalized touches to every space.</h5>
                    </div>
                </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
                <div className="row">
                {categories.map((category) => (
                    <div key={category.id} onClick={() => handleCardClick(category?.category_url,category?.id)}  className="col-6 px-0 ">
                        <div className="card card1 rounded-0 bg-img border-0 cursor">
                            <div className="bg-img-inner " style={{ backgroundImage: `url(${category.image})` }}></div> 
                            <div className="card-body text-white">
                                <div className="card-overlay p-xl-3 p-lg-3 p-sm-1 p-md-1 p-1">
                                    <div className="text-end sys-view">
                                        <img className="arw-img" src="./arrow.svg" alt="" />
                                    </div>
                                    <div className="text-start pt-xl-3 pt-lg-3  pt-md-1  pt-sm-1 pt-1  px-lg-4 px-xl-4 px-md-4 px-sm-3 px-1">
                                        <div className="d-flex gap-2 align-items-center pb-xl-4 pb-lg-4 pb-md-1 pb-sm-1 pb-1">
                                            <img className="icon-img"src={category.icon} alt={category.title}/>
                                            <div><h3 className="mb-0">{category.title.toUpperCase()}</h3></div>
                                        </div>
                                        <p className="mb-0 sys-view front-dot">
                                        {category.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
        <div className='second-modal'>
            <div class=" modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered">
                    <div class="modal-content">
                        <div className='text-end'><button type="button" className="btn-close p-3" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        <Form/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Section1;
