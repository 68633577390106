import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2

const Form = () => {
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        service: '',
        message: '',
    });

    useEffect(() => {
        axios.get('https://interior-admin.skyraan.net/api/category_list')
            .then(response => {
                setCategories(response.data.data);
            })
            .catch(error => {
                console.error("There was an error fetching the categories!", error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(' http://127.0.0.1:8000/api/enquiry_form', formData)
            .then(response => {
                console.log('Form submitted successfully:', response.data);
                setFormData({
                    name: '',
                    email: '',
                    number: '',
                    service: '',
                    message: ''
                });
                // Show SweetAlert popup on successful submission
                Swal.fire({
                    title: 'Success!',
                    text: 'Form submitted successfully our team members will contact you soon!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            })
            .catch(error => {
                console.error("There was an error submitting the form!", error);
            });
    };

    return (
        <div className="card p-xl-5 p-lg-5 p-md-5 p-sm-5 p-3">
            <h4 className="text-center site_txt fw-normal">Get an Estimate for your Interior work</h4>
            <p className='form-fs text-center pt-1 mb-1 lh-base'>
                Fill out the form below, and our expert team will get in touch with you to discuss your interior design needs.
            </p>
            <form className="px-xl-4 px-lg-4 px-sm-4 px-md-4 px-2" onSubmit={handleSubmit}>
                <div className="form-group py-2">
                    <label className='site_txt' htmlFor="name">Your Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name here"
                    />
                </div>
                <div className="form-group py-2">
                    <label className='site_txt' htmlFor="email">Email ID</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter your Email ID"
                    />
                </div>
                <div className="form-group py-2">
                    <label className='site_txt' htmlFor="number">Phone number</label>
                    <div className="d-flex">
                        <span className="dial_code">+91</span>
                        <input
                            type="number"
                            className="form-control border-start-0 rounded-end"
                            id="number"
                            name="number"
                            value={formData.number}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                        />
                    </div>
                </div>
                <div className="form-group py-2">
                    <label className="site_txt" htmlFor="service">Service looking for</label>
                    <select
                        className="form-control select-with-arrow"
                        id="service"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                    >
                        <option className='placeholder-option' value="" disabled hidden>
                            Select service you are looking for
                        </option>
                        {categories.map(category => (
                            <option className='text-dark' key={category.id} value={category.id}>
                                {category.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group py-2">
                    <label className='site_txt' htmlFor="message">Message</label>
                    <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Enter your Message"
                    ></textarea>
                </div>
                <div className='text-center pt-3'>
                    <button type="submit" className="px-5 site_bg btn text-white py-2">
                        <h6 className='mb-0 fw-none'>Send Enquiry</h6>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Form;
