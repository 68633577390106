// src/component/About.js
import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
const Aboutus = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);

  return (
    <div className='about-us'>
        <div className="abt-banner">
            <div
                className="carousel slide carousel-fade hidden-xs"
                id="featured"
                data-bs-ride="carousel"
                data-bs-interval="1900" 
            >
                <ol className="carousel-indicators">
                    <li
                        data-bs-target="#featured"
                        data-bs-slide-to="0"
                        className="active indicator"
                    ></li>
                    <li
                        data-bs-target="#featured"
                        data-bs-slide-to="1"
                        className="indicator"
                    ></li>
                    <li
                        data-bs-target="#featured"
                        data-bs-slide-to="2"
                        className="indicator"
                    ></li>
                </ol>
                <div>
                    <div className="carousel-inner position-relative">
                        <div
                            className="carousel-item active position-relative"
                            data-bs-interval="1900"
                        >
                            <img
                                className="carousel-image"
                                src="./abt-bnr-2.svg"
                                alt="banner 1"
                            />
                        </div>
                        <div
                            className="carousel-item position-relative"
                            data-bs-interval="1900"
                        >
                            <img
                                className="carousel-image"
                                src="./abt-bnr-3.svg"
                                alt="banner 2"
                            />
                        </div>
                        <div
                            className="carousel-item position-relative"
                            data-bs-interval="1900"
                        >
                            <img
                                className="carousel-image"
                                src="./abt-bnr-4.svg"
                                alt="banner 3"
                            />  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container text-center" data-aos="zoom-in">
            <div className="p-xl-5  p-lg-5 p-md-3  p-sm-3 p-3 mx-0 mx-xl-5 mx-lg-5 mx-md-0 mx-sm-0 text-start">
                <p className='lh-lg abt-top'>The journey of Ghats Interiors began over a decade ago in Dubai, where we specialized in comprehensive fit-out joinery for both residential and commercial spaces. Our expertise includes flooring, lighting, wall panelling, color schemes, art displays, window treatments, and much more. We bring your vision to life in a variety of settings, including homes, home offices, retail stores, restaurants, cafes, hotels, healthcare facilities, and educational institutions.
                Now, we are excited to announce our grand opening in our hometown of Coimbatore, Tamil Nadu, where we continue to fulfill our customers' interior design needs with the same dedication and craftsmanship that defines Ghats Interiors. Let us help you create the perfect environment for your unique concepts!</p>
            </div>
        </div>
        <div className="container" data-aos="fade-up">
            <div className="card border-0 site_bg p-4 rounded-4">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div><img className='w-100 h-100' src="./abt1.svg" alt="aboutus" /></div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className='text-white'>
                            <h2 className='py-4'>Our Vision</h2>
                            <p className='lh-lg'>At Ghats Interiors, we aim to create inviting interiors that bring our clients' dreams to life. Serving Coimbatore and Tamil Nadu, we design comfortable homes and attractive business spaces with cost-effective solutions. Using the latest in design technology, we blend modern furnishings with local culture to exceed expectations. Our services include custom woodworking, flooring, wall paneling, and premium furniture for homes and offices, alongside tailored lighting solutions. Our goal is to deliver lasting quality and creativity in every space we transform.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-4'>
                <div className="card border-0 site_bg p-4 rounded-4 ">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-2 order-xl-1 order-lg-1">
                            <div className='text-white'>
                                <h2 className='py-4'>Our Mission</h2>
                                <p className='lh-lg'>At Ghats Interiors, our mission is to deliver exceptional interior design services, from small spaces to large environments, distinguishing ourselves from competitors in Dubai. We strive to reflect our clients' visions through personalized designs and carefully chosen materials that meet their preferences. Once the design and dimensions are defined, we proceed with implementation, ensuring high-quality, durable results. Serving Coimbatore and Tamil Nadu, we take pride in creating beautiful, lasting environments that align with our clients' tastes.</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 order-1 order-xl-2 order-lg-2">
                            <div><img className='w-100 h-100' src="./abt2.svg" alt="aboutus" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Aboutus;
