import React from 'react'
import { TbMailFilled } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";


const Header = () => {
  return (
    <div className="site_bg py-2 top-head">
        <div className='container'>
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex  justify-content-between">
                    <div className='d-flex align-items-center'>
                    <TbMailFilled style={{color:'white',width:'25px'}} />                   
                    <span className='text-white ps-xl-2 ps-lg-2 ps-sm-1 ps-md-2 ps-1'><a href="mailto:ghatsinteriors@gmail.com" style={{ textDecoration: 'none' ,color:'#fff'}} >ghatsinteriors@gmail.com</a></span>
                    </div>
                    <div className='d-flex align-items-center'>
                    <FaPhoneAlt style={{color:'white'}} />
                    <span className='text-white ps-2 sys-view'><a href="tel:+918122270799"  style={{ textDecoration: 'none' ,color:'#fff'}} >+91 8122270799</a> &nbsp;&nbsp;&nbsp;  <a href="tel:+918122270799"  style={{ textDecoration: 'none' ,color:'#fff'}} >+91 7025123626</a></span>
                    <span className='text-white ps-2 mbl-view'><a href="tel:+918122270799"  style={{ textDecoration: 'none' ,color:'#fff'}} >+91 8122270799 </a><br/><a href="tel:+918122270799"  style={{ textDecoration: 'none' ,color:'#fff'}} >+91 7025123626</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header