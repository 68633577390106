import React, { useEffect} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Section2 = () => {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,    
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 3 }
            },
            {
                breakpoint: 800,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 600,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 500,
                settings: { slidesToShow: 1 }
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1 }
            }
        ]
    };
    useEffect(() => {
        AOS.init({
            duration: 1000, // Customize animation duration (in ms)
        });
    }, []);
    return (
        <div>
            <div className="section6 py-xl-3 py-lg-3 py-sm-4 py-md-4 py-3">
            <h2 className='site_txt text-center py-xl-5 py-lg-5 py-sm-4 py-md-4 py-3'>Brands We Have Associated With</h2>
            <div className="marquee">
                <div className="marquee-content">
                    <img src="./marq1.svg" alt="Image 1" />
                    <img src="./marq2.svg" alt="Image 2" />
                    <img src="./marq3.svg" alt="Image 3" />
                    <img src="./marq4.svg" alt="Image 4" />
                    <img src="./marq5.svg" alt="Image 4" />
                </div>
                <div className="marquee-content" aria-hidden="true">
                    <img src="./marq1.svg" alt="Image 1" />
                    <img src="./marq2.svg" alt="Image 2" />
                    <img src="./marq3.svg" alt="Image 3" />
                    <img src="./marq4.svg" alt="Image 4" />
                    <img src="./marq5.svg" alt="Image 4" />
                </div>
            </div>
        </div>

            <div className="section5 py-xl-5 py-lg-5 py-sm-4 py-md-4 py-3" data-aos="fade-up">
                <h2 className="site_txt text-center">Testimonials</h2>
                <div className="container-fluid">
                    <div className='row justify-content-center py-2' >
                        <div className="col-xl-7 col-lg-7 col-md-8 col-sm-9 col-12 text-center">
                        <p>Curious about the work culture at Ghats Interiors? Check out the reviews from our valued customers below. We prioritize customer satisfaction and would love for you to join us on this journey. Your experience matters to us!</p>
                        </div>
                    </div>
                </div> 
                
                <div className="container px-0 carousel-container h-100">
                    <Slider {...settings} className='text-center h-100'>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2">Priya Ramesh</h5>
                            <p className='text-start flex-grow-1 testi-dots'>I couldn't be happier with my new living room designed by Ghats Interiors! The furniture is both stylish and comfortable, and the lighting makes the space feel so warm and inviting. Highly recommend their services!</p>
                            {/* <div className='d-flex gap-2'>
                                <img className=' secondary-img rounded-0 ' src="./testimonial.png" alt="Brand 1" />
                                <img className='secondary-img rounded-0 ' src="./testimonial.png" alt="Brand 1" />
                                <img className=' secondary-img rounded-0 ' src="./testimonial.png" alt="Brand 1" />
                                <img className='secondary-img rounded-0 ' src="./testimonial.png" alt="Brand 1" />
                            </div> */}
                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2"> Arun Kumar</h5>
                            <p className='text-start flex-grow-1 testi-dots'> Ghats Interiors transformed our office space into a modern and functional environment. The team understood our needs and delivered exceptional design solutions. Our employees love the new layout!</p>

                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2"> Meera Sharma</h5>
                            <p className='text-start flex-grow-1 testi-dots'> The Pooja room designed by Ghats Interiors exceeded my expectations! It feels like a sacred sanctuary now, and I love the traditional yet modern touches they incorporated. Thank you for such a beautiful space!</p>

                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2"> Vinayak Subramanian</h5>
                            <p className='text-start flex-grow-1 testi-dots'> We hired Ghats Interiors for our restaurant renovation, and they did a fantastic job! The atmosphere is now vibrant and welcoming, attracting more customers than ever. Their attention to detail is impressive!</p>
                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2">  Kavya Nair</h5>
                            <p className='text-start flex-grow-1 testi-dots'>I recently renovated my bedroom with the help of Ghats Interiors, and it has become my favorite place in the house! The furniture selection and color scheme perfectly match my style. Highly recommended!</p>

                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2">  Rajesh Babu</h5>
                            <p className='text-start flex-grow-1 testi-dots'>Ghats Interiors created a stunning and efficient workspace for our startup. Their design ideas were innovative, and they delivered on time. We appreciate their professionalism and creativity!</p>
                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2">Lakshmi Menon</h5>
                            <p className='text-start flex-grow-1 testi-dots'>The wall panelling in our home designed by Ghats Interiors has transformed our living area completely! It's elegant and adds so much character to the space. We’re thrilled with the results!</p>
                        </div>
                        <div className="card p-3 mx-auto rounded-5  h-100" >
                            <img className='prfile-img rounded-5' src="./testimonial.png" alt="Brand 1" />
                            <h5 className="text-start site_txt py-2"> Arvind Suresh</h5>
                            <p className='text-start flex-grow-1 testi-dots'>At Ghats Interiors, we believe that interior design and decoration should tell a story, whether through traditional or modern styles. Whether you’re looking to enhance your living space or create an inviting commercial environment, we’re here to help. We offer custom design solutions!</p>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Section2;
