import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import Form from './Form'
import { TbMailFilled } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";

const Contactus = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
  return (
    <div>
<div className="contact-banner">
    <div
        className="carousel slide carousel-fade hidden-xs"
        id="featured"
        data-bs-ride="carousel"
        data-bs-interval="2000"
    >
        <ol className="carousel-indicators">
            <li
                data-bs-target="#featured"
                data-bs-slide-to="0"
                className="active indicator"
            ></li>
            <li
                data-bs-target="#featured"
                data-bs-slide-to="1"
                className="indicator"
            ></li>
            <li
                data-bs-target="#featured"
                data-bs-slide-to="2"
                className="indicator"
            ></li>
        </ol>
        <div>
            <div className="carousel-inner position-relative">
                <div
                    className="carousel-item active position-relative"
                    data-bs-interval="1800"
                >
                    <img
                        className="carousel-image"
                        src="./abt-bnr-4.svg"
                        alt="banner 1"
                    />
                </div>
                <div
                    className="carousel-item position-relative"
                    data-bs-interval="1800"
                >
                    <img
                        className="carousel-image"
                        src="./abt-bnr-2.svg"
                        alt="banner 2"
                    />
                </div>
                <div
                    className="carousel-item position-relative"
                    data-bs-interval="1800"
                >
                    <img
                        className="carousel-image"
                        src="./abt-bnr-4.svg"
                        alt="banner 3"
                    />  
                </div>
            </div>
        </div>
    </div>
</div>

        <div className="container px-3 px-xl-5 px-lg-5 px-md-0 px-sm-0 py-5 ">
            <div className="row">
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"  data-aos="fadde-up">
                    <h2 className='site_txt'>CONTACT US</h2>
                    <div className='pt-3'>
                        <div>
                            <h6 className=' pb-2'>Have Questions? We're Here to Help!</h6>
                            <p>
                                Reach out to us at &nbsp;
                                <TbMailFilled style={{ color: '#7E277C', width: '17px', height: '17px' }} />
                                <a
                                    href="mailto:ghatsinteriors@gmail.com"
                                    className="site_txt ps-1"
                                    style={{ textDecoration: 'underline' }} 
                                >
                                    ghatsinteriors@gmail.com
                                </a>.
                                Our team is ready to assist you with all your interior design inquiries!
                            </p>
                        </div>
                        <div className='py-4'>
                            <h6 className=' pb-2'>Get in Touch!</h6>
                            <p>
                                Call us at &nbsp;
                                <FaPhoneAlt style={{ color: '#7E277C', width: '15px', height: '15px' }} />
                                <a
                                    href="tel:+918122270799"
                                    className="site_txt ps-1"
                                    style={{ textDecoration: 'underline'}} 
                                >
                                    +91 8122270799,
                                </a>
                                <a
                                    href="tel:+917025123626"
                                    className="site_txt ps-1"
                                    style={{ textDecoration: 'underline'}} 
                                >
                                    +91 7025123626
                                </a>&nbsp;
                                for a consultation or to discuss your interior design project. We’re just a phone call away!
                            </p>                        
                        </div>
                        <h6 className=' pb-2'>Address:</h6>

                        <div className='d-flex align-items-start'>
                        <img src="/location.svg" alt="" className='location' />
                        <p className='ps-3'>Plot 22/23/24, Keeranatham IT Park Road,<br /> Near TNEB Substation,<br />  Saravanampatti,<br /> Coimbatore, <br /> TamilNadu 641035</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <h4 className='site_txt'>Visit Us at Ghats Interiors!</h4>
                    <p className='lh-base'>Check our location on the map and come see how we can transform your space. We look forward to welcoming you!</p>
                    <div className="text-center">
                        
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3915.3403459630663!2d76.997974!3d11.087992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDA1JzE2LjgiTiA3NsKwNTknNTIuNyJF!5e0!3m2!1sen!2sin!4v1731473719920!5m2!1sen!2sin" 
                            style={{ width: '100%', height: '250px', border: '0' }}
                            allowfullscreen="" 
                            loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade">
                         </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-bg site_bg p-4"  data-aos="fade-up">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <Form/>
                </div>
            </div>
        </div>
        <div className='mt-3'>
            <div className='text-center site_bg '>
                <h6 className='text-center text-white py-2 mb-0 fw-normal copy-cnt'>Copyright © 2024 Ghats Inreriors. All Rights Reserved.</h6>
            </div>
        </div>
    </div>
  )
}
export default Contactus
