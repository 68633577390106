import Form from './Form';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';

const Product = () => {
    const [subCategories, setSubCategories] = useState([]);
    const { categoryUrl,id } = useParams();
    const [content, setContent] = useState(''); 
    const [categoryTitle, setCategoryTitle] = useState(''); 
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [additionalCategories, setAdditionalCategories] = useState([]);

    const navigate = useNavigate();
    
    useEffect(() => {
        fetchSubCategories(id,categoryUrl); 
    }, [id,categoryUrl]);

    const fetchSubCategories = (categoryId) => {
        const formData = new FormData();
        formData.append("category_id", categoryId); 

        axios.post('https://interior-admin.skyraan.net/api/subcategory_list', formData)
            .then(response => {
                setSubCategories(response.data.data);
                setContent(response.data.content);
                setCategoryTitle(response.data.category_title);
                setAdditionalCategories(response.data.additional_categories);
                // console.log(response.data.additional_categories);

            })
            .catch(error => {
                console.error("There was an error fetching the categories!", error);
            });
    };

    const handleCategoryClick = (categoryId, categoryUrl) => {
        // console.log(categoryId, categoryUrl);
        navigate(`/${categoryUrl}/${categoryId}`);
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, 
        });
    }, []);

    const staticImages = [
        '/hero_images_1.png',
        '/hero_images_2.png',
        '/hero_images_3.png',
        '/hero_images_4.png',
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true); // Start fading out

            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % staticImages.length); // Change image
                setIsFading(false); // Start fading in
            }, 500); // Wait 2 seconds to fade out before changing image
        }, 9000); // Change image every 12 seconds

        return () => clearInterval(interval);
    }, []);
    return (
        <div className='product'>
            <div className="product-banner">
                <div className="carousel slide carousel-fade hidden-xs" id="featured" data-bs-ride="carousel">
                    <div className="carousel-inner position-relative">
                        <div className="carousel-item active position-relative">
                            <div className="product-banner image-overlay">
                                <img
                                    className={`carousel-image ${isFading ? 'fade' : ''}`}
                                    src={staticImages[currentImageIndex]}
                                    alt={`banner ${currentImageIndex + 1}`}
                                />
                                <div className="black-overlay"></div>
                                <div className="text-overlay">
                                    <h1 className="lh-lg">
                                        <span dangerouslySetInnerHTML={{ __html: categoryTitle.toUpperCase() }} />
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container top text-center">
                <div className="p-xl-5 p-lg-5 p-md-3 p-sm-3 p-3 mx-0 mx-xl-5 mx-lg-5 mx-md-0 mx-sm-0 text-start">
                    <p className='lh-lg'>
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    </p>
                </div>
            </div>
            {categoryUrl !== "other-services" ? (
            <div className="container main-div"  data-aos="fade-up">
                <div className="row pb-xl-3 pb-lg-3 pb-md-3 pb-sm-3 pb-2">
                    {subCategories.map((subCategory, index) => (
                        <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 px-xl-3 px-lg-3 px-md-2 px-sm-2 px-2 pb-5 h-100">
                            <a
                                className="card border-0 text-decoration-none h-100"
                                data-bs-toggle="modal"
                                href="#exampleModalToggle"
                                role="button"
                                onClick={() => setSelectedSubCategory(subCategory)}
                            >
                                <img className="w-100  product-card rounded-top-3" src={subCategory.subcategory_image} alt="product-image" />
                                <div className="p-2">
                                    <h5 className="site_txt">{subCategory.subcategory_name}</h5>
                                    <p className="text-secondary subcategory-dot">
                                        {subCategory.subcategory_description}
                                    </p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <div>
                    <p>Looking to transform your  <span className='site_txt fs-5'> {categoryTitle ? categoryTitle : 'Loading category title...'} </span> Whether it's a residential or commercial space, Ghats Interiors in Saravanampatti, Coimbatore, is here to bring your dream interior to life. Get in touch with us today to see your vision in a stunning live design!</p>
                    <span className=''>Wanted to decorate your interior space? <Link className='underline site_txt' to="/contact-us">Contact us now to get started!</Link></span>
                </div>
                <div className='d-flex justify-content-between py-5 '>
                    {additionalCategories.map((category, index) => (
                        <div key={index} onClick={() => handleCategoryClick(category.id, category.category_url)}>
                        <div className="card p-3 site_bg rounded-4 cursor bottom-card h-100">
                                <div className='d-flex gap-2 justify-content-center'>
                                    {index === 0 ? <img src="/arrow2.svg" alt="arrow" /> : null}
                                    <h6 className='mb-0 text-white fw-normal next-prev-btn'>{category.title}</h6>
                                    {index === 1 ? <img src="/arrow1.svg" alt="arrow" /> : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            ) : (
                <div>
                    <div className='container-fluid'>
                        <div className="row pb-xl-3 pb-lg-3 pb-md-3 pb-sm-3 pb-2 other-div " >
                            {subCategories.map((subCategory, index) => (
                                <div
                                    key={index}
                                    className="col-12 mb-4"
                                    style={{
                                        backgroundColor: index % 2 === 0 ? '#FFEFFE' : '#F8F8F8',
                                    }}
                                >
                                    <a
                                        className="border-0 text-decoration-none h-100"
                                        data-bs-toggle="modal"
                                        href="#exampleModalToggle"
                                        role="button"
                                        onClick={() => setSelectedSubCategory(subCategory)}
                                    >
                                        <div className='container p-4'>
                                            <h2 className="site_txt text-center pb-3">{subCategory.subcategory_name}</h2>
                                            <div className="row card border-0 rounded-0"  data-aos="fade-up">
                                                <div className="d-flex flex-column flex-md-row px-0">
                                                    {index % 2 === 0 ? (
                                                        <>
                                                            {/* Image First, Text Second for even rows */}
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-stretch order-0 order-md-0 order-xl-0 order-lg-0">
                                                                <div className="w-100">
                                                                    <img className="w-100 h-100" src={subCategory.subcategory_image} alt="product-image" style={{ objectFit: 'cover' }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-4 d-flex align-items-center order-1 order-md-1 order-xl-1 order-lg-1">
                                                                <div>
                                                                    <p>{subCategory.subcategory_description}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* Text First, Image Second for odd rows */}
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-4 d-flex align-items-center order-1 order-md-0 order-xl-0 order-lg-0">
                                                                <div>
                                                                    <p>{subCategory.subcategory_description}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-stretch order-0 order-md-1 order-xl-1 order-lg-1">
                                                                <div className="w-100">
                                                                    <img className="w-100 h-100" src={subCategory.subcategory_image} alt="product-image" style={{ objectFit: 'cover' }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
         
            {/* Modal */}
            <div className="first-modal">
                <div
                    className="modal fade"
                    id="exampleModalToggle"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex="-1"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content bg-transparent border-0">
                            <div className="modal-body pb-4 ">
                                {selectedSubCategory && (
                                    <img
                                        className="w-100 h-100 rounded-3"
                                        src={selectedSubCategory.subcategory_image}
                                        alt={selectedSubCategory.subcategory_name}
                                    />
                                )}
                            </div>
                            <div className="border-0 text-center">
                                <button
                                    className="btn btn-bg text-dark buttons py-2 rounded-3"
                                    data-bs-target="#exampleModalToggle2"
                                    data-bs-toggle="modal"
                                    data-bs-dismiss="modal"
                                >
                                    <h6 className="fw-normal mb-0">Get an Estimate</h6>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Second Modal */}
            <div className='second-modal'>
                <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className='text-end'><button type="button" className="btn-close p-3" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
