import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { useNavigate } from 'react-router-dom'; // Ensure useNavigate is imported
import { Link } from 'react-router-dom';
import { TbMailFilled } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth", 
  });
};

const Footer = () => {
  const [categories, setCategories] = useState([]); // Initialize categories state
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    axios.get('https://interior-admin.skyraan.net/api/category_list')
      .then(response => {
        setCategories(response.data.data); // Set categories to the response data
      })
      .catch(error => {
        console.error("There was an error fetching the categories!", error);
      });
  }, []);

  const handleCardClick = (categoryUrl, id) => {
    navigate(`/${encodeURIComponent(categoryUrl)}/${encodeURIComponent(id)}`); // Use navigate to route
  };

  // Split the categories into two halves
  const halfIndex = Math.ceil(categories.length / 2);
  const firstHalf = categories.slice(0, halfIndex);
  const secondHalf = categories.slice(halfIndex);

  return (
    <div className='pt-4'>
      <div className='footer'>
        <div className="container py-4">
          <div className='text-center'>
            <Link className="logo" to="/"><img src="/logo.svg" alt="Logo" /></Link>
          </div>
          <div className="row pt-5">
            <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12 text-center text-md-center text-sm-center text-lg-start text-xl-start">
              <p className='foo-cnt'>Transform your space with Ghats Interiors specializing in stylish interiors for homes and commercial properties. Let us bring your vision to life with designs that reflect your unique style!
              </p>
              <div className='sys-view pb-4'>
                <p>Social media</p>
                <div className="d-flex gap-3 align-items-center social-media justify-content-md-center justify-content-sm-center justify-content-xl-start justify-content-lg-start justify-content-start">
                  <a href="https://www.facebook.com/people/Shajan-Thomas/61567789374128/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                    <img src="/fb.png" alt="facebook" />
                  </a>
                  <a href="https://www.instagram.com/ghats_99/" target="_blank" rel="noopener noreferrer">
                    <img src="/insta.png" alt="instagram" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 div1">
              <h5 className='text-dark'>OUR PROJECTS</h5>
              <div className='pt-3'>
                {firstHalf.map((category, index) => (
                  <p className="cursor" key={index} onClick={() => handleCardClick(category.category_url, category.id)}>
                    {category.title.toUpperCase()}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 pt-5 div1">
              {secondHalf.map((category, index) => (
                <p  className="cursor" key={index} onClick={() => handleCardClick(category.category_url, category.id)}>
                  {category.title.toUpperCase()}
                </p>
              ))}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 div2 pt-4 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0">
              <h5 className='text-dark'>CONTACT US</h5>
              <div className='pt-3'>
                <div>
                  <TbMailFilled style={{color:'#7E277C'}} />
                  <span className='text-dark ps-3'><a
                      href="mailto:ghatsinteriors@gmail.com"
                      className=" ps-1 text-dark"
                      style={{ textDecoration: 'none' }} 
                  >
                      ghatsinteriors@gmail.com
                  </a>.
                  </span>
                </div>
                <div className='py-4'>
                  <FaPhoneAlt style={{color:'#7E277C'}} />
                  <span className='text-dark ps-3'>
                    <a
                        href="tel:+918122270799"
                        className="text-dark ps-1"
                        style={{ textDecoration: 'none'}} 
                    >
                        +91 8122270799
                    </a>
                  </span>
                  <span className='text-dark ps-3'>
                    <a
                        href="tel:+917025123626"
                        className="text-dark ps-1"
                        style={{ textDecoration: 'none'}} 
                    >
                        +91 7025123626
                    </a>
                  </span>
                </div>
                <div className='d-flex align-items-start'>
                  <img src="/location.svg" alt="" className='location' />
                  <p className='ps-3'>Plot 22/23/24, Keeranatham IT Park Road, Near TNEB Substation,<br />  Saravanampatti,<br /> Coimbatore, <br /> TamilNadu 641035.</p>
                </div>
              </div>
              <div className='mbl-view pb-4'>
                <p>Social media</p>
                <div className="d-flex gap-3 align-items-center social-media justify-content-md-center justify-content-sm-center justify-content-xl-start justify-content-lg-start justify-content-start">
                  <a href="https://www.facebook.com/people/Shajan-Thomas/61567789374128/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                    <img src="/fb.png" alt="facebook" />
                  </a>
                  <a href="https://www.instagram.com/ghats_99/" target="_blank" rel="noopener noreferrer">
                    <img src="/insta.png" alt="instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-end' id='scroll-to-top' style={{display:'inline-block'}}>
          <img onClick={scrollToTop} src="/top.svg" alt="scroll-to-top" />
        </div>
      </div>
      <div className='text-center site_bg'>
        <h6 className='text-center text-white py-2 mb-0 fw-normal copy-cnt'> Copyright © 2024 Ghats Inreriors. All Rights Reserved.</h6>
      </div>
    </div>
  );
};

export default Footer;
