import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Header from './component/Header';
import Navbar from './component/Navbar';
import Home from './component/Home/Home';
import Aboutus from './component/Aboutus';
import Footer from './component/Footer';
import Contactus from './component/Contactus';
import Product from './component/Product';
import ScrollToTop from './component/ScrollToTop';

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation();

  return (
    <div>
      <Header />
      <Navbar />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/:categoryUrl/:id" element={<Product />} />
        </Routes>
        {location.pathname !== '/contact-us' && <Footer />}
    </div>
  );
}

export default App;
