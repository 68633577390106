import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import axios from 'axios';
import {useNavigate } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) { 
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    
    const [showFirstDropdown, setShowFirstDropdown] = useState(false);
    const [showNestedDropdown, setShowNestedDropdown] = useState(false);
  
    const handleFirstDropdownToggle = () => {
      setShowFirstDropdown(!showFirstDropdown);
      setShowNestedDropdown(false); 
    };
  
    const handleNestedDropdownToggle = (e) => {
      e.stopPropagation();
      setShowNestedDropdown(!showNestedDropdown);
    };
  
    const closeAllDropdowns = () => {
      setShowFirstDropdown(false);
      setShowNestedDropdown(false);
    };

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get('https://interior-admin.skyraan.net/api/category_list')
            .then((response) => {
                setCategories(response.data.data);
            })
            .catch((error) => {
                console.error('There was an error fetching the categories!', error);
            });
    }, []);

    const handleCardClick = (categoryUrl, id) => {
        console.log(categoryUrl, id);
        navigate(`/${encodeURIComponent(categoryUrl)}/${encodeURIComponent(id)}`);
    };

  return (
    <div className="container">
      <div className={`row nav-bar py-lg-2 py-sm-2 py-md-2 py-3 ${isFixed ? "fixed-tops" : ""}`}>
        <div className="col-4">
          <Link to="/"><img className='logo' src="/logo.svg" alt="Logo" /></Link>
        </div>
        <div className="col-8 text-end align-self-center ">
            <div className='sys-view'>
              <Link
                className={`text-decoration-none ${location.pathname === '/' ? 'active' : ''}`}
                to="/"
              >
                <span className="text-dark opacity-75 text-center py-3 px-xl-4 px-lg-4 px-md-3 px-sm-3 px-2">HOME</span>
              </Link>
              <Link
                className={`text-decoration-none ${location.pathname === '/about-us' ? 'active' : ''}`}
                to="/about-us"
              >
                <span className="text-dark opacity-75 text-center py-3 px-xl-4 px-lg-4 px-md-3 px-sm-3 px-2">ABOUT US</span>
              </Link>

              <div className="btn-group">
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={handleFirstDropdownToggle}
                >
                  SERVICES
                  <IoIosArrowDown className='ps-1'/>
                </button>

                <ul className={`border-0 dropdown-menu first-menu-li mt-5 ${showFirstDropdown ? 'show' : ''}`}>
                  <li className="dropdown-submenu ">
                    <button
                      className="dropdown-item dropdown-toggle"
                      onClick={handleNestedDropdownToggle}
                    >
                      Interior designing
                    </button>
                    {showNestedDropdown && (
                      <ul className={`border-0 dropdown-menu ${showNestedDropdown ? 'show' : ''}`}>
                        {categories
                          .filter(item => item.title !== 'Other Services')
                          .map((item, catIndex) => (
                            <li key={catIndex} onClick={() => handleCardClick(item?.category_url, item?.id)}>
                              <a className="dropdown-item" href="">
                                {item.title}
                              </a>
                            </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li><a className="dropdown-item" href="/other-services/60">Other services</a></li>
                </ul>
                {showFirstDropdown && <div className="backdrop" onClick={closeAllDropdowns} />}
              </div>
              
              <Link
                className={`text-decoration-none ${location.pathname === '/contact-us' ? 'active' : ''}`}
                to="/contact-us"
              >
                <span className="text-dark opacity-75 text-center py-3 px-xl-4 px-lg-4 px-md-3 px-sm-3 px-2">CONTACT</span>
              </Link>
            </div>
            <div className='mbl-view'>
            <button class="btn " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <img className='w-100' src="/menu.svg" alt="menu" />
            </button>
              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                  <h5 id="offcanvasRightLabel"><img className='logo' src="/logo.svg" alt="Logo" /></h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body text-dark">
                    <span class="navbar-text ">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                            <li class="nav-item px-2 py-2">
                                <Link to="/" class="nav-link text-dark text-start" aria-current="page" >HOME</Link>
                            </li>
                            <li class="nav-item px-2 py-2">
                                <Link to="/about-us" class="nav-link text-dark text-start" aria-current="page" >ABOUT US</Link>
                            </li>
                            <li class="nav-item px-2 py-2">
                                <Link to="/contact-us" class="nav-link text-dark text-start" aria-current="page" >CONTACT US</Link>
                            </li>
                            <li className="nav-item dropdown px-2 py-2">
                              <Link className="nav-link dropdown-toggle text-dark text-start" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  SERVICES
                              </Link>
                              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                  {categories.map((item, catIndex) => (
                                      <li key={catIndex} onClick={() => handleCardClick(item?.category_url, item?.id)}>
                                          <a className="dropdown-item" href="">{item.title}</a>
                                      </li>
                                  ))}
                              </ul>
                          </li>
                        </ul>
                    </span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;